<template>
  <div class="modal" style="z-index: 9999 !important">
    <div class="modal__header">
      <div class="modal__header__title">
        <span>Tags</span>
      </div>
      <div class="action">
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </div>
    </div>

    <div class="modal__body" v-if="localTags !== []">
      <ul>
        <div v-for="(tag, index) in localTags" :key="index">
          <li>
            <span>${{ tag }}</span>
          </li>
        </div>
      </ul>
    </div>

    <div class="modal--actions mt-3">
      <z-btn small text="fechar" @click="$emit('close')" />
    </div>
  </div>
</template>

<script>
import ApiClient from "@/services/ApiClient";
import moment from "moment";
export default {
  props: {
    tags: { type: Array, default: () => [] },
  },
  data() {
    return {
      nonce: 1,
      y: 0,
      x: 0,
      search: null,
      isLoading: false,
      email: "",
      http: new ApiClient(),
      items: [{ header: "Selecione ou crie um e-mail" }],
      model: [],
      activator: null,
      attach: null,
      colors: [
        "#1976D2",
        "#1976D2",
        "#1976D2",
        "#1976D2",
        "#1976D2",
        "#1976D2",
      ],
      editing: null,
      localTags: [],
      editingIndex: -1,
    };
  },
  created() {
    this.localTags = this.tags;
  },
  methods: {
    required(value) {
      if (value instanceof Array && value.length == 0) {
        return "Campo obrigatório.";
      }
      return !!value || "Campo obrigatório.";
    },
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
    treatFirstData() {
      const tempData = [];

      if (this.firstData) {
        this.firstData.forEach((x) => {
          const newObj = Object.fromEntries(
            Object.entries(x).map(([k, v]) => [k.toLowerCase(), v])
          );
          tempData.push(newObj);
        });
      }

      this.firstData = tempData;
    },
    transformString(text) {
      this.treatFirstData();
      if (this.firstData && this.firstData.length > 0) {
        for (let j = 0; j < this.firstData.length; j++) {
          const keys = Object.keys(this.firstData[j]);
          for (let i = 0; i < keys.length; i++) {
            let fieldConfig = this.databaseFields[keys[i]];
            let fieldValue = "";

            if (!this.firstData[j][keys[i]] || !fieldConfig) continue;

            if (fieldConfig.type === "decimal") {
              fieldValue = parseFloat(this.firstData[j][keys[i]])
                .toFixed(2)
                .replace(".", ",");
            } else if (fieldConfig.type === "date") {
              fieldValue = moment(this.firstData[j][keys[i]]).format(
                "DD/MM/yyyy"
              );
            } else if (fieldConfig.type === "datetime") {
              fieldValue = moment(this.firstData[j][keys[i]]).format(
                "DD/MM/yyyy hh:mm"
              );
            } else {
              fieldValue = this.firstData[j][keys[i]];
            }

            //eslint-disable-next-line
            let patternString = "(?<=^|\\s)\\$" + keys[i] + "(?![a-zA-Z_])";
            let pattern = new RegExp(patternString, "gi");
            if (fieldValue) text = text.replace(pattern, fieldValue);
          }
        }
      }
      return text;
    },
    formatEmail() {
      let S = "";
      if (this.model.length) {
        this.model.forEach((d) => {
          S += `${d.text},`;
        });
      }

      return S.substring(0, S.length - 1);
    },
    submit() {
      if (
        this.$refs.form.validate() &&
        this.templateToSaveProp.emailSender &&
        this.templateToSaveProp.emailSubject
      ) {
        // if (this.templateToSaveProp && this.choosenConnectionProp) {

        this.emailInformationProp.editor.exportHtml(async (data) => {
          let OBJ = { content: {} };
          OBJ.email = this.formatEmail();
          OBJ.subject = this.templateToSaveProp.emailSubject;
          OBJ.replyTo = this.templateToSaveProp.replyTo;
          OBJ.mailSender = this.templateToSaveProp.emailSender;
          OBJ.senderName = this.templateToSaveProp.senderName;
          OBJ.content.message = JSON.stringify(data);
          //  console.log(OBJ.content.message);
          OBJ.content.message = this.transformString(OBJ.content.message);
          //   console.log(OBJ.content.message);
          OBJ.content.description = "";
          OBJ.content.databaseConfigId = this.choosenConnectionProp?.id;

          try {
            this.isLoading = true;
            const response = await this.http.post(
              `templatesConfiguration/sendEmailTemplateTest`,
              {
                ...OBJ,
              }
            );
            if (response) {
              this.isLoading = false;
              this.$toast.success("Sucesso!");
              this.$emit("close");
            }
          } catch (error) {
            this.$toast.error(`${error.body.detail}`);
            this.isLoading = false;
            throw error;
          }
        });

        // } else {
        //   this.$toast.warning("Preencha todas as informações necessárias");
        //   this.$emit("onErroInInformations");
        // }
      } else {
        this.$toast.error(`Preencha todos os campos de remetente obrigatórios`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: #ffffff;
  padding: $z-s-1;
  border-radius: 15px;
  color: $z-black-1;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $z-s-1;

    &__title {
      span {
        font-weight: 600;
        text-transform: uppercase;
        line-height: 15px;
        font-size: 1rem;
      }
    }
  }

  &__body {
    color: #4e4e4e;
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
